import { CloseOutlined } from "@ant-design/icons";
import {
  CERTIFICATION_MAP,
  NAME_FILTERS,
  ON_OFF_MAP,
  PRICE_MAP,
} from "constants/filtersName";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import * as S from "./style";
import { compareParamsUrlValue } from "utils/url";
import { useLocation } from "react-router-dom";

const Chip = ({ label, content, handleDeleteFilter }) => {
  const { reset, watch } = useFormContext();
  const [fieldValue, setFieldValue] = useState(null);
  const location = useLocation();
  //used to format data for Chip component
  useEffect(() => {
    if (content) {
      switch (label) {
        case "pricetp": {
          const value = PRICE_MAP[content];
          return setFieldValue(value);
        }
        case "onOff": {
          const value = ON_OFF_MAP[content];
          return setFieldValue(value);
        }
        case "certification": {
          const value = CERTIFICATION_MAP[content];
          return setFieldValue(value);
        }
        case "transactDate":
        case "mfgDate":
        case "height":
        case "width":
        case "sizeTable":
        case "price": {
          const min = content[0] ? content[0] : "";
          const max = content[1] ? content[1] : "";
          const value = `${min} ~ ${max}`;
          return setFieldValue(value);
        }
        default: {
          if (Array.isArray(content)) {
            const value = content.join(", ");
            return setFieldValue(value);
          }
          return setFieldValue(content);
        }
      }
    }
  }, [label, JSON.stringify(content)]);

  // reset when close all chip
  const handleResetFilterEmpty = () => {
    const tagsElement = document.getElementsByClassName("chip");
    console.log(tagsElement)
    if (tagsElement.length === 1 && compareParamsUrlValue(location, watch()) === 0) {
      handleDeleteFilter?.(true);
    }
  }

  return (
    <>
      {content?.length > 0 && (
        <S.Chip className="chip">
          <S.ChipHead>{NAME_FILTERS[label]}</S.ChipHead>
          <S.ChipContent>{fieldValue}</S.ChipContent>
          <S.ChipClose
            onClick={() => {
              reset((formValues) => ({
                ...formValues,
                [label]: "",
              }));
              handleResetFilterEmpty();
            }}
          >
            <CloseOutlined />
          </S.ChipClose>
        </S.Chip>
      )}
    </>
  );
};

export default React.memo(Chip);
