import queryString from "query-string";
/**
 * Add query parameters to URL
 * Remove query parameter in URL if value is empty
 * @param {object} router - router object
 * @param {string} paramName- query parameter name
 * @returns {void}
 */
export const addParamUrl = (history, requestURL) => {
  const paramKeys = Object.keys(requestURL);
  const paramValues = Object.values(requestURL);
  let currentRouterQuery: any = queryString.parse(history.location.search);

  paramKeys.forEach((key, index) => {
    // if (key === "field" || key === "order") {
    //   return;
    // }
    paramValues[index]
      ? (currentRouterQuery[key] = paramValues[index])
      : delete currentRouterQuery[key];
  });

  history.replace({
    pathname: history.location.pathname,
    search: queryString.stringify(currentRouterQuery),
  });
};
/**
 * Get value of URL parameter
 * @param {object} router - router object
 * @param {string} paramName- query parameter name
 * @returns {string}
 */
export const getParamUrlValue = (location, paramName) => {
  const query = queryString.parse(location?.search);
  return query[paramName]?.length > 0 ? query[paramName] : undefined;
};

export const compareParamsUrlValue = (location, params) => {
  if (location.search === "" && location.pathname === "/artist") return 1;

  const query = queryString.parse(location?.search);
  for (const [key, value] of Object.entries(params)) {
    // console.log(JSON.stringify(value), " ===== ", JSON.stringify(query[key]), " ===== ", key)
    if ((!value && !query[key]) || key === "querySearch") continue; 
    if (typeof(value) === "object" 
      && typeof(query[key]) === "string" 
      && JSON.stringify(value) === JSON.stringify([query[key]])
    ) continue;
    if (JSON.stringify(value) !== JSON.stringify(query[key])) return 0;
  }
  return 1
}

export const changeParamsUrl = (history, params) => {
  for (const [key, value] of Object.entries(params)) {
    if (!value || value === "" || value === ([])) delete params[key]
  }
  history.replace({
    pathname: history.location.pathname,
    search: queryString.stringify(params),
  });
}
