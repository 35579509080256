const Endpoints = {
  LOGIN: "/auth/sign-in",
  LOGIN_TWITTER: "/login-twitter",

  GET_ME: "/api/v1/me",

  //Auction
  GET_ALL_AUCTION: "/auction/get-all-auction",
  GET_DETAIL_AUCTION: "/auction/detail-auction",
  ADD_AUCTION: "/auction/create-auction",
  UPDATE_AUCTION: "/auction/edit-auction",
  UPDATE_MULTI_AUCTION: "/auction/edit-multi",
  DELETE_AUCTION: "/auction/delete-auction",
  CALCULATE_AUCTION: "/auction/caculate",
  DOWNLOAD_AUCTION: "/auction/export-excel",
  DOWNLOAD_FILTER_AUCTION: "/auction/export-excel-after-search",
  UPDATE_ON_TABLE_AUCTION: "/auction/edit-data-mainpage",
  FILTER_LEVEL_2: "/auction/data-after-filter",
  CREATE_NEW_AUCTIONS: "/auction/create-auctions",

  //Author
  GET_ALL_AUTHOR: "/author/get-all-author",
  GET_ALL_AUTHOR_NOT_PAGING: "/author/get-author",
  GET_DETAIL_AUTHOR: "/author/get-detail-author",
  CREATE_AUTHOR: "/author/create-author",
  UPDATE_AUTHOR: "/author/edit-author",
  DELETE_AUTHOR: "/author/delete-author",
  DOWNLOAD_EXCEL: "/author/export-excel",

  //Currency
  GET_DETAIL_CURRENCY: "/currenry/get-detail-currency",

  //Company
  GET_ALL_COMPANY: "/company/get-all-company",

  //Upload file
  UPLOAD_FILE: "/upload-files",
};

export default Endpoints;
