import { Descriptions } from "antd";
import * as S from "./style";

interface Props {
  list?: any;
  column?: number;
  title?: string;
  collapse?: boolean;
}

function DescriptionWrap({ list, column = 3, title, collapse = undefined }: Props) {
  let width = column === 2 ? 25 : null;

  return (
    <S.Container collapse={collapse} width={width}>
      <Descriptions
        bordered
        column={{ xxl: column, xl: column, lg: column, xs: column }}
        title={title}
      >
        {list.map((item: any, index: any) => (
          <Descriptions.Item
            key={index}
            label={item.label}
            span={item.colSpan ?? ""}
          >
            {item.value}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </S.Container>
  );
}

export default DescriptionWrap;
